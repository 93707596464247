import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1280 109",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M-103 76.18c6.332-.633 10.772-7.3 15.248-11.127 12.79-10.933 28.037-18.772 43.581-25.035C-4.34 23.965 42.782 21.992 85.027 17.865c43.778-4.276 87.064-5.418 130.949-2.472 60.138 4.036 118.883 22.52 177.209 36.575 26.374 6.355 53.332 10.474 80.054 15.042 44.01 7.523 87.14 18.924 131.361 25.448 38.356 5.66 77.124 10.631 115.804 13.394 40.7 2.907 78.598-1.738 118.895-6.388 79.927-9.222 159.726-18.732 239.031-32.557 77.8-13.564 155.46-28.343 232.74-44.611 19.52-4.111 40.39-8.56 58.83-16.382C1373.82 4.25 1378.15 2 1382.47 2",
      stroke: "#FFFAE8",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }, null, -1)
  ])))
}
export default { render: render }